import Vue from 'vue'
import VueRouter from 'vue-router'
import util from '@/assets/js/util'

Vue.use(VueRouter)

const routes = [{
    	path: "/",
        component: {template: "<router-view></router-view>"},
    	redirect: "/index"
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('@/views/chat.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue')
    },
    {
        path: '/notFound',
        name: 'notFound',
        component: () => import('@/views/notFound.vue')
    }
]

const router404=[
	{
		path: "*",
		redirect: "/notFound",
	}
]
const router = new VueRouter({
	mode: "history",
	base: "/", //加上这一行
	routes: [
        ...routes,
		...router404
	],
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		};
	},
});

// router.beforeEach((to, from, next) => {
// 	if(to.name=='notFound'){
// 		next();
// 	}else{
// 		if(window.localStorage.getItem("token")){
//             next();
// 		}else{
// 			util.toLogin();
// 		}
// 	}
// });
export default router
