import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import { Message, Image } from 'element-ui';
Vue.prototype.$message = Message;
Vue.use(Image);

import https from '@/api/index' // 导入api接口
Vue.prototype.$https= https;

import util from '@/assets/js/util.js' // 导入util
Vue.prototype.$util= util;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
