import router from '@/router'
import { Message } from "element-ui";
export default {
    toLogin() {
        router.replace("/login");
    },
    laytips(type, msg, duration) {
        //提示信息
        Message.closeAll();
        Message({
            showClose: true,
            message: msg,
            type: type,
            duration: duration ? duration : 0,
        });
    },
    trim(s) {
        //去空格
        return s.replace(/(^\s*)|(\s*$)/g, "");
    },
}
