import axios from '@/request/service'; // 导入http中创建的axios实例

const login = {    
	sendCode (params) {   
        return axios.post('/vmei/user/sendSms',params);    
    },
    login (params) {
        return axios.post('/vmei/user/login',params);    
    },
    logout (params) {
        return axios.post('/vmei/user/logout',params);    
    }
    // 其他接口…………
}

export default login;