import axios from '@/request/service'; // 导入http中创建的axios实例

const chat = {    
	getOptions () {   
        return axios.get('/vmei/chat/getOptions',{});    
    },
    getResponse (params) {   
        return axios.get('/vmei/chat/getResponse',params);    
    },
    getChatHistoryList (params) {
        return axios.get('/vmei/chat/chatHistoryList',params);    
    },
    getDefaultVideo (params) {
        return axios.get('/vmei/chat/getDefaultVideo',params);    
    }
    
    // 其他接口…………
}

export default chat;